import React, { useEffect, useState } from "react"

import SignInStudentDisplay from "./SignInStudentDisplay.js"
import { gql } from "graphql-request"
import useSSRStorage from "../../hooks/useSSRStorage.js"
import { useMutation } from "react-query"
import graphqlClient from "../../services/graphql-client.js"
import { navigate } from "gatsby"
import {
  CURRENT_USER_QUERY,
  STUDENT_BY_ID_QUERY,
  INTROSPECTION_QUERY,
} from "../../graphql_requests.js"
import { useStudentStore } from "../../../store.js"
import { isJWTExpired } from "../../services/helpers.js"

const SIGN_IN_STUDENT = gql`
  mutation ($login: String!) {
    studentSignIn(input: { login: $login }) {
      jwtToken
    }
  }
`
const SignInStudentContainer = () => {
  function ValidateCode(login) {
    return login?.length === 5 || login?.length === 6
  }

  const isBrowser = typeof window !== "undefined" // check if executing instance is the browser or the building process
  const [loginCredentials, setLoginCredentials] = useState({})
  const [signInErrorMessage, setSignInErrorMessage] = useState()
  const [jwt, setJwt] = useSSRStorage("jwt_student", null)
  const [studentId, setStudentId] = useState()
  const updateStore = useStudentStore((store) => store.updateStore)

  const fetchStudentId = async () => {
    try {
      const queryResult = await graphqlClient.request(CURRENT_USER_QUERY)
      setStudentId(queryResult.currentUserId)
    } catch (error) {
      console.error(error)
    }
  }

  const fetchStudent = async () => {
    try {
      // const introResult = await graphqlClient.request(INTROSPECTION_QUERY)
      // console.log("introspection:", introResult)
      const queryResult = await graphqlClient.request(STUDENT_BY_ID_QUERY, {
        id: studentId,
      })
      updateStore("student", { ...queryResult.studentById })
      updateStore("exercises", {
        covered: queryResult.studentById.inputSessionsByStudent.nodes,
      })
      navigate("/student/profile/")
    } catch (error) {
      console.error(error)
    }
  }

  const signInStudentsMutation = useMutation(
    (variables) => graphqlClient.request(SIGN_IN_STUDENT, variables),
    {
      onSuccess: (data) => {
        const token = data.studentSignIn.jwtToken
        if (token && isBrowser) {
          console.log(`Bearer ${token}`)
          graphqlClient.setHeader("authorization", `Bearer ${token}`)
          window.localStorage.removeItem("jwt_teacher")
          window.localStorage.removeItem("jwt_student")
          setJwt(token)

          // navigate("/student/profile/")
        }
      },
    }
  )

  const handleChange = ({ target: { name, value } }) => {
    setLoginCredentials((loginCredentials) => ({
      ...loginCredentials,
      [name]: value,
    }))
    // TODO: do some input-validation here
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const login = loginCredentials.code?.trim()
    if (ValidateCode(login)) {
      setSignInErrorMessage(null)
      signInStudentsMutation.mutate({ login })
    } else {
      setSignInErrorMessage(
        "Your Code has 6 characters, please put in these characters."
      )
    }
  }

  useEffect(() => {
    if (!jwt || isJWTExpired(jwt)) {
      window.localStorage.removeItem("jwt_student")
    } else {
      fetchStudentId()
    }
  }, [jwt])

  useEffect(() => {
    if (studentId) {
      fetchStudent()
    }
  }, [studentId])

  return (
    <>
      <SignInStudentDisplay
        signInErrorMessage={signInErrorMessage}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        signInStudentsMutation={signInStudentsMutation}
      />
    </>
  )
}

SignInStudentContainer.propTypes = {}

export default SignInStudentContainer
